<template>
  <dialog-outline v-model="showDialog">
    {{ title }}
    <template v-slot:icon-name>
      {{ icon }}
    </template>

    <template v-slot:context>
      <!-- record details banner, which represents a subject that current operation will be performed -->
      <record-banner
        :record="record"
        :is-check-in="true"
        :ext="currentExtension"
      ></record-banner>

      <!--  Check In Dialog context -->
      <check-in-dialog-context
        :record="record"
        :extension="extension"
        :check-in-model="checkInModel"
        :check-in-option="checkInOption"
        :file-page-count="pageCount"
        @selected-extension="selectedExtension"
      >
      </check-in-dialog-context>

      <!--  check In WIP -->
      <slot name="checkInWIP"></slot>

      <!--  check In Compound -->
      <slot name="checkInCompound"></slot>
    </template>

    <!--  Check In Dialog Actions -->
    <template v-slot:actions>
      <template>
        <!-- perform Check In -->
        <base-button
          @click="checkInFunction()"
          :disabled="!enableCommandCheckIn"
          >{{ commandCheckInLabel }}</base-button
        >
      </template>
    </template>
    <template v-slot:actionCancel>
      <!-- cancel Dialog -->
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline></template
>

<script>
// design
import { iconFileCheckIn } from "@/design/icon/iconConst";

// model
import {
  extensionName,
  fileType,
  findFileType
} from "@/model/record/fileModel";
import { createCheckInOption } from "@/model/record/checkInModel";

// services
import {
  fullRecordName,
  isCompoundFile
} from "@/services/record/recordService";

// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { eventNames } from "@/model/common/events/eventConst";
import { recordBannerAble } from "@/mixins/shared/bannerAble/recordBannerAble";

export default {
  name: "CheckInDialog",
  mixins: [dialogOutlineMixin, recordBannerAble],
  components: {
    CheckInDialogContext: () =>
      import("@/components/shared/core/checkIn/CheckInDialogContext"),
    BaseButton: () => import("@/components/shared/base/BaseButton")
  },
  props: {
    record: {
      type: Object,
      required: true
    },
    pageCount: {
      type: Number
    },
    checkInEvent: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      icon: iconFileCheckIn,
      checkInModel: {
        visibleCheckInProgress: false,
        currentError: undefined,
        checkInExtension: {
          key: "As It Is",
          value:
            (this.record?.extension?.length ?? 0) === 0
              ? extensionName.tif
              : this.record.extension
        }
      },
      checkInOption: createCheckInOption(),
      extensionName: extensionName,
      currentExtension:
        this.checkInModel?.checkInExtension?.value ?? this.record?.extension
    };
  },
  computed: {
    /**
     * compute whether Command Check In is enabled
     * @return {boolean} true if Command Check In is enabled
     */
    enableCommandCheckIn() {
      return this.checkInModel?.checkInExtension !== undefined;
    },

    /**
     * Get Check In Dialog title
     * @return {string} Check In Dialog title
     */
    title() {
      return `${this.commandCheckInLabel} ${this.fullRecordName}`;
    },

    /**
     * Check In command Label
     * @return {string} Check In command Label
     */
    commandCheckInLabel() {
      return `Check In`;
    },

    /**
     * full Record Name
     * @return {string} full Record Name
     */
    fullRecordName() {
      return fullRecordName(this.record);
    },

    /**
     * record extension
     * @return {string}
     */
    extension() {
      return this.record?.extension ?? "";
    }
  },
  methods: {
    /**
     * Performs check In
     */
    checkInFunction() {
      this.checkInEvent(this.checkInModel, this.checkInOption);
    },

    /**
     * Sets extension for checkIn from recordInfo (localFile)
     */
    setCheckInExtension() {
      try {
        // only validate data if component data has been properly rendered
        if (!this.checkInModel) {
          return;
        }

        const fileType = this.getDefaultExtension();

        // get only first element of multiple possible extensions,
        // because we are already handling multiple options in switch statement below
        const extension = findFileType(fileType).extensions[0];

        switch (extension) {
          case extensionName.tif:
          case extensionName.tiff:
            this.checkInModel.checkInExtension.value = extensionName.tif;
            break;

          case extensionName.jpg:
          case extensionName.jpeg:
            this.checkInModel.checkInExtension.value = extensionName.jpeg;
            break;

          case extensionName.pdf:
            this.checkInModel.checkInExtension.value = extensionName.pdf;
            break;

          default:
            this.checkInModel.checkInExtension.value = extensionName.tif;
        }
        this.selectedExtension(this.checkInModel.checkInExtension.value);
      } catch (e) {
        this.currentError = e.toString();
      }
    },

    /**
     * Gets Default Extension to be selected
     * @returns {number|*} FileType
     */
    getDefaultExtension() {
      return isCompoundFile(this.record) && this.pageCount > 1
        ? fileType.pdf
        : this.record?.localFile?.extension?.type ?? fileType.imageTiff;
    },

    /**
     * Emit Selected Extension to Parent
     * @param {string}extension
     */
    selectedExtension(extension) {
      this.currentExtension = extension;
      this.$emit(eventNames.selectedExtension, extension);
    }
  },
  updated() {
    // Makes sure to listen for updated props
    // this Update call is necessary, so that extension can be set as soon as this dialog in rendered
    this.setCheckInExtension();
  }
};
</script>

<style scoped></style>
